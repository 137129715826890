<template>
    <div class="searchList">
        <div>
            <BannerVue></BannerVue>
        </div>
        <!-- 搜索列表 -->
        <div class="listContent">
            <div class="webMain">
                <div class="listbox">
                    <div class="ele" v-for="item in articleList" :key="item.id" @click="toDetail(item)">
                        <div class="left">
                            <img v-if="item.thumbnail" :src="$store.state.requestAPI + item.thumbnail" alt="">
                            <img v-else  src="../../assets/web/index/编组 33@2x.png" alt="">
                        </div>
                        <div class="center">
                            <!-- <div class="eleTitle"> -->
                            <p class="eleTitle p17">{{ item.title }}</p>
                            <p class="eleContent p16">
                                {{ item.summary }}
                            </p>
                        </div>
                        <div class="right">
                            <p class="time p16">{{ (item.created).slice(0, 10) }}</p>
                            <span class="detail">Detail</span>
                        </div>
                    </div>
                </div>
                <div class="block">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="paging.pageNum"
                        :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import BannerVue from '@/components/EnglishWeb/Banner.vue'
import { searchArticleListAPI } from '@/api/article/EnglishArticle'
export default {
    components: { BannerVue },
    data() {
        return {
            articleList: [],
            paging: {
                pageNum: 1,
                pageSize: 7
            },
            total: 0
        }
    },
    methods: {
        // 跳转到详情页
        async toDetail(val) {
            this.$store.commit('changeFirstName', {title: 'home'});
            this.$store.commit('changeActiveName', {title: 'search'});
            this.$router.push(`/english/detail/${val.id}`)
        },
        // 分页
        handleCurrentChange(val) {
            this.paging.pageNum = val;
            this.getList();
        },
        async getList() {
            let info = {
                query: this.$route.query.content,
                ...this.paging
            }
            const res = await searchArticleListAPI(JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                this.articleList = res.data.list;
                this.total = res.data.total;
            }

        },

    },
    created() {
        this.getList();
    }
}
</script>

<style lang="scss" scoped>
.active {
    background: linear-gradient(180deg, #FFAB0C 0%, #FFCD6D 100%);
    box-shadow: -6px 0px 9px 0px rgba(0, 0, 0, 0.3), 6px 0px 9px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    color: black;
}

::v-deep .el-pager li.active {
    color: var(--second-color) !important;
}
</style>